.labels {
    font-family: 'Lora', serif;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input {
    line-height: 20px;
}


.leafone{
    position: absolute;
    z-index: -1;
    top: 0;
}
.leaftwo{
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
}
.leaffour{
    position: absolute;
    z-index: -1;
    top: 250px;
    right: 40%;
    opacity: 0.3;
    height: 300px;
}

@media screen and (max-width: 840px)  {
    .leaftwo {
        top: 500px;
    }

    .leaffour {
        top: 800px
    }
}
